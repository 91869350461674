<template>
  <div>
    <el-dialog
      title="Расскажите о проблеме"
      destroy-on-close
      width="600px"
      :visible.sync="show"
      :close-on-click-modal="false"
    >
      <el-row>

        <el-form
          ref="supportForm"
          size="small"
          label-position="top"
          :model="form"
          :rules="rules"
        >
          <el-form-item label="Тема" prop="subject">
            <el-input maxlength="255" show-word-limit v-model="form.subject"></el-input>
          </el-form-item>

          <el-form-item label="Текст" prop="text">
            <el-input type="textarea" :autosize="{ minRows: 6}" v-model="form.text"></el-input>
          </el-form-item>

          <el-form-item label="Вложение">

            <el-upload
              class="support-modal-upload"
              ref="file-uploader"
              drag
              action=""
              multiple
              :file-list="fileList"
              :on-change="changeFileList"
              :on-remove="changeFileList"
              :auto-upload="false"
              :show-file-list="true"
            >
              <i class="el-icon-upload" style="margin: 5px 0 16px 0"></i>
              <div class="el-upload__text-block">
                <div class="el-upload__text" >Перетащите или нажмите для загрузки файла</div>
                <div class="el-upload__tip" style="line-height: 20px">
                  Доступные для загрузки форматы: txt, docx, doc, xls, xlsx, rar, zip, jpg, jpeg, png, pdf.
                  <br>
                  Вес: до 50мб
                </div>
              </div>
            </el-upload>

          </el-form-item>

        </el-form>

      </el-row>

      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="small"
          :loading="loading"
          @click="sendForm"
        >
          Отправить
        </el-button>
      </div>
    </el-dialog>

    <span ref="wrapper">
      <slot name="reference"></slot>
    </span>
  </div>
</template>

<script>

import {off, on} from "element-ui/src/utils/dom";
import requestSender from "@/api/base/requestSender";

export default {
  name: "SupportModal",
  components: {},


  data() {


    return {
      show: false,
      loading: false,

      fileList: [],

      form: {
        subject: null,
        text: null,
      },
      rules: {
        subject: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'change'}],
        text: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'change'}],
      },

    }
  },
  mounted() {
    if (this.$refs.wrapper.children) {
      on(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  },
  beforeDestroy() {
    if (this.$refs.wrapper.children) {
      off(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  },
  watch: {},
  methods: {
    showModal() {
      this.show = true;
    },

    changeFileList() {
      this.fileList = this.$refs["file-uploader"].uploadFiles
    },



    sendForm() {
      this.$refs.supportForm.validate((result) => {
        if (!result) {
          return;
        }

        this.loading = true;

        let form = new FormData();
        this.fileList.forEach(file => {
          form.append('file[]', file.raw);
        })
        form.append('subject', this.form.subject);
        form.append('text', this.form.text);


        requestSender('post', 'home/send-support-message', form)
          .then(data => {
            this.show = false;
            this.$notify.success({
              title: 'Отправлено',
              message: 'Ваше обращение успешно отправлено'
            });

          })
          .finally(() => {
            this.loading = false;
          })
      });

    },
  },



}
</script>

<style lang="scss">
.support-modal-upload{
  width: 100%;

  .el-upload, .el-upload-dragger {
    width: 100%;
  }
}

</style>