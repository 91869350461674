<template>
  <div>
    <el-dialog
      title="Создание процедуры"
      destroy-on-close
      width="600px"
      :visible.sync="show"
      :close-on-click-modal="false"
    >
      <el-row>

        <el-form
          ref="procedureCreateForm"
          size="small"
          label-position="top"
          :model="procedure"
          :rules="rules"
        >
          <el-form-item label="Название" prop="name">
            <el-input maxlength="255" show-word-limit v-model="procedure.name"></el-input>
          </el-form-item>

          <el-form-item label="Тип процедуры" prop="type">

            <el-select v-model="procedure.type" style="width:100%">
              <el-option
                v-for="(typeName, typeId) in $companyConfiguration.settings.getEnum('compensation_procedure_types')"
                :key="typeId"
                :label="typeName"
                :value="typeId"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Год оценки эффективности" prop="year">
            <el-date-picker
              v-model="procedure.year"
              type="year"
              value-format="yyyy"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="Дата выгрузки данных" prop="data_export_date">
            <el-date-picker
              v-model="procedure.data_export_date"
              style="width:100%"
              placeholder="Выберите дату"
              :picker-options="{firstDayOfWeek: 1}"
              type="date"
              value-format="yyyy-MM-dd"
              format="dd.MM.yyyy"
              :default-value="new Date(new Date().getFullYear()-1, 11, 1)"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="Даты проведения" prop="dates">
            <div style="display: flex; justify-content: space-between">
              <el-date-picker
                v-model="procedure.date_start"
                style="width:270px"
                placeholder="Выберите дату начала"
                type="date"
                value-format="yyyy-MM-dd"
                format="dd.MM.yyyy"
                :picker-options="{
                  firstDayOfWeek: 1,
                  disabledDate(time){
                    if( !procedure.date_end ) return false;
                    return time > new Date(procedure.date_end);
                  }
                }"
              >
              </el-date-picker>
              -
              <el-date-picker
                v-model="procedure.date_end"
                style="width:270px"
                placeholder="Выберите дату окончания"
                type="date"
                value-format="yyyy-MM-dd"
                format="dd.MM.yyyy"
                :picker-options="{
                  firstDayOfWeek: 1,
                  disabledDate(time){
                    if( !procedure.date_start ) return false;
                    return time < new Date(procedure.date_start);
                  }
                }"
              >
              </el-date-picker>
            </div>
          </el-form-item>


        </el-form>

        <div class="mode-danger" style="color: #145969; margin-top: 20px">
          Обратите внимание, после создания процедуры будет недоступно изменение Типа процедуры и Даты выгрузки данных.
        </div>

      </el-row>


      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="show = false">Отмена</el-button>
        <el-button
          type="primary"
          size="small"
          :loading="loading"
          @click="createProcedure"
        >
          Создать процедуру
        </el-button>
      </div>
    </el-dialog>

    <span ref="wrapper">
      <slot name="reference"></slot>
    </span>
  </div>
</template>

<script>

import {off, on} from "element-ui/src/utils/dom";
import UsersTable from "@/components/user/UsersTable.vue";
import EntitySavedReportForm from "@/components/report/builder/EntitySavedReportForm.vue";
import PeriodSelector from "@/components/PeriodSelector.vue";
import requestSender from "@/api/base/requestSender";

export default {
  name: "CompensationProcedureCreateModal",
  components: {PeriodSelector, EntitySavedReportForm, UsersTable},


  data() {
    const checkDates = (rule, value, callback) => {
      if( this.procedure.date_start && this.procedure.date_end ){
        callback();
      } else {
        callback(new Error('Поле обязательно для заполнения'));
      }
    };

    return {
      show: false,
      loading: false,

      procedure: {
        name: null,
        type: null,
        data_export_date: null,
        date_start: null,
        date_end: null,
      },
      rules: {
        name: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'change'}],
        type: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'change'}],
        year: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'change'}],
        data_export_date: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'change'}],
        dates: [
          { validator: checkDates, required: true, trigger: 'change'},
        ],
      },

    }
  },
  mounted() {
    if (this.$refs.wrapper.children) {
      on(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  },
  beforeDestroy() {
    if (this.$refs.wrapper.children) {
      off(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  },
  watch: {},
  methods: {
    showModal() {
      this.show = true;
    },


    createProcedure() {
      this.$refs.procedureCreateForm.validate((result) => {
        if (!result) {
          return;
        }

        this.loading = true;
        requestSender('post', 'compensation-procedure/create', {
          ...this.procedure,
        })
          .then(() => {
            this.$emit('procedure-created');
            this.show = false;
          })
          .finally(() => {
            this.loading = false;
          });

      });

    },
  },



}
</script>

<style>

</style>