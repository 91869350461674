<template>
  <div v-loading="loading">
    <div class="smz-home__block-name">{{ settings[name + '_block_name'] }}</div>
    <div class="smz-home__block-desc" v-html="settings[name+'_block_desc']"></div>
    <div
      v-if="attachments.length"
      style="margin-top: 20px"
    >
      <i class="far fa-clone" style="font-size: 26px"></i>
      Полезная информация:
    </div>
    <div
      v-for="attachment in attachments.filter(a => a.hasFile)"
      style="margin-top: 10px; margin-left: 32px"
    >
      <a
        class="file-link"
        @click.prevent="downloadAttachment(attachment)"
      >
        <i class="fas fa-cloud-download-alt"></i>
        {{ attachment.desc }}
      </a>
    </div>


  </div>
</template>

<script>

import requestSender from "@/api/base/requestSender";
import {downloader} from "@/mixins/downloader";

export default {
  name: "home-sub-block-with-files",
  components: {},
  mixins: [downloader],

  props: {
    name: {type: String, required: true},
    settings: {type: Object, required: true},
  },

  data() {
    return {
      loading: false,
      attachments: [],
    }
  },
  mounted() {
    this.loadAttachments();
  },
  watch: {},
  methods: {
    loadAttachments() {
      this.loading = true;

      requestSender('get', 'home/get-attachments', {
        type: this.name,
      })
        .then(data => {
          this.attachments = data.attachments;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    downloadAttachment(attachment) {
      this.downloadFile(
        false,
        this.$api.exportImport.export,
        {
          entity: 'home_files',
          data: {
            id: attachment.id
          }
        }
      );
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/css/project-variables";

.file-link {
  margin-right: 5px;
  cursor: pointer;
  //text-decoration: underline;
  color: $--color-primary;
}
</style>